<template>
  <div class="pa-2">
    <v-card>
      <v-card-title
        ><div></div>
        <v-spacer></v-spacer>
        <div>
          <v-btn @click="sendMessages()" color="primary" class="ma-1">
            Разослать
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text
        ><a-input-text v-model="message" :model="{ title: 'Сообщение' }"
      /></v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message: "",
    };
  },
  created() {
    this.$root.title = "Рассылки";
  },
  methods: {
    async sendMessages() {
      if (this.message) {
        await this.$axios.g(
          this.$root.api.baseUrl + "ultrascope/send-message",
          { params: { message: this.message } }
        );
        this.$root.$emit("show-info", {
          text: "Сообщение отправлено",
        });
      } else {
        this.$root.$emit("show-info", {
          text: "Исправте ошибки",
        });
      }
    },
  },
};
</script>